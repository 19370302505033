import React from "react";
import { Helmet } from "react-helmet";

const NewsletterSignup = ({ className }) => {
  return (
    <div id="newsletter-signup">
      <iframe
        className="h-[425px] max-w-full"
        src="https://wp.riverworksmarketing.com/newsletter/"
      />
    </div>
  );
};

export default NewsletterSignup;
