import React, { useRef, useLayoutEffect } from "react";
import Link from "../utilities/link";
import { gsap } from "gsap";
import sharedAnimations from "../../utilities/shared-animations";

const LogoSvg = ({ svgClasses, intro, white }) => {
  const svgRef = useRef(),
    sectionSelector = gsap.utils.selector(svgRef);

  if (intro) {
    sharedAnimations();
  }

  useLayoutEffect(() => {
    if (!intro) {
      return;
    }

    let logoIntro = gsap
      .timeline()
      .fromTo(
        svgRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: "default",
        }
      )
      .fromTo(
        sectionSelector(".logo-wave"),
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 0.75,
          ease: "default",
        }
      );

    return () => {
      logoIntro.kill();
    };
  }, [sectionSelector]);

  return (
    <svg
      ref={svgRef}
      className={`${intro ? "" : "hidden sm:block"} w-full ${
        svgClasses ? svgClasses : !intro ? "max-w-[160px]" : "w-full"
      }`}
      viewBox="0 0 315.93 60.49"
    >
      <g>
        <g>
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M18.68,1.67c8.59,0,12.54,5.58,12.54,12.24c0,4.35-1.81,8.43-5.69,10.65c-0.48,0.28-0.61,0.92-0.28,1.37
			l10.28,14.36c0.45,0.63,0,1.52-0.78,1.52h-7.9c-0.31,0-0.6-0.15-0.78-0.4L15.54,26.59c-0.18-0.25-0.47-0.4-0.78-0.4h-2.93
			c-0.53,0-0.96,0.43-0.96,0.96v7.06c0,0.53-0.43,0.96-0.96,0.96H3.28c-0.53,0-0.96-0.43-0.96-0.96V2.63c0-0.53,0.43-0.96,0.96-0.96
			H18.68z M10.87,10.45v7.01c0,0.53,0.43,0.96,0.96,0.96h6.38c3.43,0,4.72-2.1,4.72-4.42c0-2.58-1.29-4.51-4.72-4.51h-6.38
			C11.3,9.49,10.87,9.92,10.87,10.45z"
          />
        </g>
        <g className={`${intro ? "" : "hidden sm:inline"}`}>
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M42.95,2.63V34.2c0,0.53-0.43,0.96-0.96,0.96h-6.67c-0.53,0-0.96-0.43-0.96-0.96V2.63
			c0-0.53,0.43-0.96,0.96-0.96h6.67C42.52,1.67,42.95,2.1,42.95,2.63z"
          />
        </g>
        <g className={`${intro ? "" : "hidden sm:inline"}`}>
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M57.95,34.56L45.2,2.99c-0.25-0.63,0.21-1.32,0.89-1.32h7.27c0.4,0,0.76,0.25,0.9,0.62l7.29,19.48
			c0.31,0.83,1.48,0.83,1.8,0l7.33-19.49c0.14-0.37,0.5-0.62,0.9-0.62h7.27c0.68,0,1.14,0.69,0.89,1.32l-12.8,31.59
			c-0.15,0.36-0.5,0.6-0.89,0.6h-7.21C58.45,35.16,58.09,34.93,57.95,34.56z"
          />
        </g>
        <g className={`${intro ? "" : "hidden sm:inline"}`}>
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M82.94,1.67h23.8c0.53,0,0.96,0.43,0.96,0.96v6.41c0,0.53-0.43,0.96-0.96,0.96H91.53
			c-0.53,0-0.96,0.43-0.96,0.96v2.89c0,0.53,0.43,0.96,0.96,0.96h12.17c0.53,0,0.96,0.43,0.96,0.96v5.85c0,0.53-0.43,0.96-0.96,0.96
			H91.53c-0.53,0-0.96,0.43-0.96,0.96v2.38c0,0.53,0.43,0.96,0.96,0.96h15.21c0.53,0,0.96,0.43,0.96,0.96v6.37
			c0,0.53-0.43,0.96-0.96,0.96h-23.8c-0.53,0-0.96-0.43-0.96-0.96V2.63C81.98,2.1,82.41,1.67,82.94,1.67z"
          />
        </g>
        <g className={`${intro ? "" : "hidden sm:inline"}`}>
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M127.37,1.67c8.59,0,12.54,5.58,12.54,12.24c0,4.35-1.82,8.43-5.69,10.65c-0.48,0.28-0.61,0.92-0.28,1.37
			l5.53,7.72c0.45,0.63,0,1.52-0.78,1.52h-7.9c-0.31,0-0.6-0.15-0.78-0.4l-5.78-8.17c-0.18-0.25-0.47-0.4-0.78-0.4h-2.92
			c-0.53,0-0.96,0.43-0.96,0.96v7.06c0,0.53-0.43,0.96-0.96,0.96h-6.63c-0.53,0-0.96-0.43-0.96-0.96V2.63
			c0-0.53,0.43-0.96,0.96-0.96H127.37z M119.55,10.45v7.01c0,0.53,0.43,0.96,0.96,0.96h6.38c3.44,0,4.72-2.1,4.72-4.42
			c0-2.58-1.29-4.51-4.72-4.51h-6.38C119.98,9.49,119.55,9.92,119.55,10.45z"
          />
        </g>
        <g className={`${intro ? "" : "hidden sm:inline"}`}>
          <g>
            <path
              className={!white ? "fill-green-1" : "fill-white"}
              d="M149.25,34.46l-8.87-31.57c-0.17-0.61,0.29-1.22,0.92-1.22h7.15c0.43,0,0.81,0.29,0.93,0.71l4.36,16.09
				c0.25,0.92,1.55,0.95,1.84,0.04l5.08-16.17c0.13-0.4,0.5-0.67,0.91-0.67h6.24c0.42,0,0.79,0.27,0.91,0.67l5.08,16.13
				c0.29,0.91,1.59,0.89,1.84-0.04l4.35-16.05c0.11-0.42,0.49-0.71,0.93-0.71h7.19c0.63,0,1.09,0.61,0.92,1.22l-8.87,31.57
				c-0.12,0.41-0.49,0.7-0.92,0.7h-7.47c-0.41,0-0.78-0.27-0.91-0.66l-5.26-16.01c-0.29-0.88-1.53-0.88-1.82,0l-5.23,16.01
				c-0.13,0.39-0.5,0.66-0.91,0.66h-7.47C149.74,35.16,149.36,34.88,149.25,34.46z"
            />
          </g>
        </g>
        <g className={`${intro ? "" : "hidden sm:inline"}`}>
          <g>
            <path
              className={!white ? "fill-green-1" : "fill-white"}
              d="M206.47,0.38c9.79,0,17.78,7.81,17.78,17.39s-7.99,17.39-17.78,17.39c-9.66,0-17.78-7.81-17.78-17.39
				S196.81,0.38,206.47,0.38z M206.47,26.88c5.15,0,9.1-4.04,9.1-9.1c0-5.07-3.95-9.1-9.1-9.1c-5.02,0-9.1,4.04-9.1,9.1
				C197.37,22.84,201.44,26.88,206.47,26.88z"
            />
          </g>
        </g>
        <g className={`${intro ? "" : "hidden sm:inline"}`}>
          <g>
            <path
              className={!white ? "fill-green-1" : "fill-white"}
              d="M243.31,1.67c8.59,0,12.54,5.58,12.54,12.24c0,4.35-1.81,8.43-5.69,10.65c-0.48,0.28-0.61,0.92-0.28,1.37
				l5.53,7.72c0.45,0.63,0,1.52-0.78,1.52h-7.9c-0.31,0-0.6-0.15-0.78-0.4l-5.78-8.16c-0.18-0.25-0.47-0.4-0.78-0.4h-2.93
				c-0.53,0-0.96,0.43-0.96,0.96v7.06c0,0.53-0.43,0.96-0.96,0.96h-6.63c-0.53,0-0.96-0.43-0.96-0.96V2.63
				c0-0.53,0.43-0.96,0.96-0.96H243.31z M235.5,10.45v7.01c0,0.53,0.43,0.96,0.96,0.96h6.38c3.43,0,4.72-2.1,4.72-4.42
				c0-2.58-1.29-4.51-4.72-4.51h-6.38C235.93,9.49,235.5,9.92,235.5,10.45z"
            />
          </g>
        </g>
        <g className={`${intro ? "" : "hidden sm:inline"}`}>
          <g>
            <path
              className={!white ? "fill-green-1" : "fill-white"}
              d="M259.94,1.67h6.67c0.53,0,0.96,0.43,0.96,0.96v8.09c0,0.88,1.08,1.29,1.67,0.64l8.43-9.38
				c0.18-0.2,0.44-0.32,0.71-0.32h8.47c0.83,0,1.27,0.99,0.71,1.61l-11.22,12.2c-0.31,0.34-0.34,0.84-0.07,1.21l12.34,16.95
				c0.46,0.63,0.01,1.52-0.77,1.52h-8.28c-0.31,0-0.59-0.15-0.77-0.39l-8.25-11.28c-0.36-0.49-1.08-0.53-1.49-0.07l-1.24,1.38
				c-0.16,0.18-0.25,0.4-0.25,0.64v8.76c0,0.53-0.43,0.96-0.96,0.96h-6.67c-0.53,0-0.96-0.43-0.96-0.96V2.63
				C258.99,2.1,259.41,1.67,259.94,1.67z"
            />
          </g>
        </g>
        <g className={`${intro ? "" : "hidden sm:inline"}`}>
          <g>
            <path
              className={!white ? "fill-green-1" : "fill-white"}
              d="M296.04,24.7c0.02,0.02,0.05,0.05,0.08,0.08c0.02,0.02,0.03,0.04,0.1,0.13c0.03,0.04,0.05,0.09,0.08,0.15
				c0.02,0.04,0.03,0.09,0.05,0.14c0.41,1.19,2.18,2.54,5.58,2.54c3.22,0,4.25-1.07,4.25-2.06c0-1.42-1.67-2.28-5.71-3.65
				c-5.32-1.76-12.32-4.34-12.32-11.25c-0.01-6.4,5.45-10.4,13.09-10.4c7.54,0,13.27,3.87,13.67,10.12c0,0.04,0,0.08,0,0.12
				c0,0.26-0.04,0.39-0.07,0.46c-0.08,0.2-0.21,0.31-0.25,0.35c-0.27,0.26-0.7,0.24-0.7,0.24h-6.47c0,0-0.33-0.01-0.57-0.21
				c-0.05-0.04-0.15-0.14-0.21-0.23c-0.04-0.08-0.1-0.16-0.12-0.31c-0.27-1.52-1.95-3.1-5.11-3.1c-2.92,0-4.08,1.16-4.08,2.28
				c0,1.33,1.55,2.02,5.54,3.39c4.64,1.63,12.54,4.38,12.54,11.72c0,6.53-5.67,9.96-13.31,9.96c-7.81,0-14.2-3.53-14.2-9.78
				c0-0.19,0.1-0.41,0.18-0.51c0,0,0.06-0.09,0.17-0.18c0.26-0.21,0.56-0.22,0.56-0.22s6.62,0,6.65,0c0.06,0,0.23,0.02,0.34,0.07
				c0.06,0.03,0.06,0.02,0.12,0.06C295.96,24.64,296.01,24.68,296.04,24.7z"
            />
          </g>
        </g>
        <g className={`hidden ${intro ? "" : "sm:inline"}`}>
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M148.92,58.59V40.4h1.31l7.93,15.76l7.93-15.76h1.31v18.19h-1.38v-15.3L158.63,58h-0.96l-7.37-14.67v15.25
			L148.92,58.59L148.92,58.59z"
          />
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M181.53,40.4l8.35,18.19h-1.52l-1.87-4.1h-11.42l-1.84,4.1h-1.54l8.35-18.19H181.53z M175.68,53.18h10.21
			l-5.11-11.29L175.68,53.18z"
          />
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M202.23,40.4c3.94,0,6.2,2.71,6.2,5.85c0,2.56-1.49,4.83-4.15,5.62l4.78,6.71h-1.68l-4.59-6.46
			c-0.19,0.02-0.37,0.02-0.56,0.02h-6.64v6.44h-1.38V40.4H202.23z M195.59,41.71v9.14h6.6c3.17,0,4.9-2.14,4.9-4.57
			c0-2.4-1.73-4.57-4.9-4.57H195.59z"
          />
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M214.12,40.4h1.38v11.1l11.73-11.1h1.87l-7.9,7.51l8.23,10.68h-1.75l-7.51-9.7l-4.66,4.43v5.27h-1.38
			L214.12,40.4L214.12,40.4z"
          />
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M233.66,40.4h12.5v1.35h-11.12V49h9.47v1.31h-9.47v6.92h11.12v1.35h-12.5V40.4z"
          />
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M249.62,40.4h14.5v1.35h-6.58v16.83h-1.38V41.76h-6.55L249.62,40.4L249.62,40.4z"
          />
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M270.14,40.4v18.19h-1.38V40.4H270.14z"
          />
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M276.73,58.59V40.4h1.24l12.59,15.83V40.4h1.38v18.19h-1.24l-12.59-15.81v15.81H276.73z"
          />
          <path
            className={!white ? "fill-blue-2" : "fill-white"}
            d="M306.92,49.24h7.95v9.35h-1.24v-3.12c-1.49,2.14-4.08,3.47-6.92,3.47c-5.25,0-9.49-4.22-9.49-9.44
			c0-5.2,4.24-9.44,9.49-9.44c3.1,0,5.88,1.52,7.6,3.85l-1.14,0.79c-1.49-1.98-3.85-3.26-6.46-3.26c-4.45,0-8.09,3.64-8.09,8.07
			c0,4.45,3.64,8.07,8.09,8.07c2.89,0,5.43-1.54,6.85-3.89v-3.22h-6.64V49.24z"
          />
        </g>
        <g
          className={`logo-wave ${
            !intro ? "origin-top-left transform-box-fill animate-scale-in" : ""
          }`}
        >
          <g>
            <path
              className={!white ? "fill-blue-2" : "fill-white"}
              d="M78.86,44.81c-2.68-1.59-5.59-2.59-7.83-3.29c-2.33-0.73-4.63-1.41-6.89-1.84c1.67,0.54,3.38,1.14,5,2.08
				c1.11,0.64,3.32,1.92,3.25,4.1c-0.1,3.23-5.77,5.11-10.51,6.12c-6.45,1.37-14.98,1.97-24,1.68C20.42,53.1,9.36,49.91,5.49,46.48
				l0,0c0,0,0,0-0.01-0.01C5.22,46.24,4.99,46,4.79,45.76c-2.03-2.92,5.73-6.08,5.73-6.08c-6.67,2.13-8.9,4.72-9.64,6.26
				c-0.02,0.05-0.04,0.09-0.06,0.14c-0.08,0.18-0.14,0.34-0.18,0.48c-0.01,0.05-0.03,0.1-0.04,0.14c-0.1,0.38-0.09,0.6-0.09,0.6
				c-0.09,2.87,4.13,5.89,11.59,8.29c7.79,2.5,18.22,4.08,29.36,4.44c22.38,0.72,41.47-3.9,41.67-10.07
				C83.2,47.38,80.37,45.71,78.86,44.81z"
            />
          </g>
          <g>
            <path
              className={!white ? "fill-blue-2" : "fill-white"}
              d="M32.33,45.29c7.71,0.23,14-0.94,14.06-2.6c0.03-0.74-0.98-1.19-1.53-1.44c-0.85-0.38-1.75-0.64-2.66-0.85
				c-1.17-0.28-2.38-0.56-3.58-0.6c-0.03,0.02-0.02,0.07,0.01,0.09c0.02,0.02,0.06,0.04,0.09,0.04c0.9,0.26,1.83,0.41,2.7,0.79
				c0.34,0.15,1.01,0.44,0.99,0.9c-0.04,1.15-5.08,1.94-11.27,1.75s-11.16-1.27-11.12-2.42c0-0.09,0.04-0.17,0.09-0.24
				c0.03-0.04,0.06-0.07,0.1-0.1c0.6-0.5,1.86-0.91,1.86-0.91c-3.6,0.89-3.57,2.07-3.57,2.07h0.01c0,0.03-0.01,0.05-0.02,0.08
				C18.43,43.53,24.62,45.06,32.33,45.29z"
            />
          </g>
          <g>
            <path
              className={!white ? "fill-blue-2" : "fill-white"}
              d="M14.94,39.77c-2.21,0.56-3.53,1.24-4.33,1.89c-0.01,0-0.01,0.01-0.02,0.01c-0.07,0.06-0.14,0.11-0.2,0.17
				c-0.08,0.07-0.17,0.13-0.24,0.2c-0.49,0.47-0.74,0.95-0.76,1.42c-0.05,1.6,2.65,3.39,7.22,4.78c4.91,1.5,11.47,2.45,18.48,2.68
				c15.49,0.52,26.01-2.56,26.11-5.76c0.04-1.43-1.69-2.4-2.62-2.92c-1.68-0.94-3.51-1.54-4.92-1.96c-0.63-0.19-1.26-0.37-1.88-0.54
				c0.26,0.12,0.51,0.24,0.76,0.38c0.73,0.4,2.17,1.19,2.13,2.59c-0.05,1.63-2.31,2.89-6.72,3.77c-4.07,0.81-9.45,1.14-15.14,0.96
				c-9.02-0.3-18.48-2.01-20.92-4.73C10.91,41.22,14.94,39.77,14.94,39.77z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const Logo = ({
  className,
  white,
  svgClasses,
  icon,
  intro,
  link,
  ...other
}) => (
  <>
    {link ? (
      <Link
        to="/"
        className={`
      block transition transform-gpu hover:scale-110
      ${white ? "logo-white" : ""}
      ${className ? className : ""}
    `}
        {...other}
      >
        <LogoSvg svgClasses={svgClasses} intro={intro} white={white} />
        {icon && (
          <svg
            className="block sm:hidden w-full max-w-[60px]"
            viewBox="0 0 84 60"
          >
            <path
              className={!white ? "fill-blue-2" : "fill-white"}
              d="M18.6799 0.669922C27.2699 0.669922 31.2199 6.24992 31.2199 12.9099C31.2199 17.2599 29.4099 21.3399 25.5299 23.5599C25.0499 23.8399 24.9199 24.4799 25.2499 24.9299L35.5299 39.2899C35.9799 39.9199 35.5299 40.8099 34.7499 40.8099H26.8499C26.5399 40.8099 26.2499 40.6599 26.0699 40.4099L15.5399 25.5899C15.3599 25.3399 15.0699 25.1899 14.7599 25.1899H11.8299C11.2999 25.1899 10.8699 25.6199 10.8699 26.1499V33.2099C10.8699 33.7399 10.4399 34.1699 9.90995 34.1699H3.27995C2.74995 34.1699 2.31995 33.7399 2.31995 33.2099V1.62992C2.31995 1.09992 2.74995 0.669922 3.27995 0.669922H18.6799ZM10.8699 9.44992V16.4599C10.8699 16.9899 11.2999 17.4199 11.8299 17.4199H18.2099C21.6399 17.4199 22.9299 15.3199 22.9299 12.9999C22.9299 10.4199 21.6399 8.48992 18.2099 8.48992H11.8299C11.2999 8.48992 10.8699 8.91992 10.8699 9.44992Z"
            />
            <path
              className={`${
                !white ? "fill-blue-2" : "fill-white"
              } origin-top-left transform-box-fill animate-scale-in`}
              d="M78.86 43.8099C76.18 42.2199 73.27 41.2199 71.03 40.5199C68.7 39.7899 66.4 39.1099 64.14 38.6799C65.81 39.2199 67.52 39.8199 69.14 40.7599C70.25 41.3999 72.46 42.6799 72.39 44.8599C72.29 48.0899 66.62 49.9699 61.88 50.9799C55.43 52.3499 46.9 52.9499 37.88 52.6599C20.42 52.0999 9.35996 48.9099 5.48996 45.4799C5.48996 45.4799 5.48996 45.4799 5.47996 45.4699C5.21996 45.2399 4.98996 44.9999 4.78996 44.7599C2.75996 41.8399 10.52 38.6799 10.52 38.6799C3.84996 40.8099 1.61996 43.3999 0.879956 44.9399C0.859956 44.9899 0.839956 45.0299 0.819956 45.0799C0.739956 45.2599 0.679956 45.4199 0.639956 45.5599C0.629956 45.6099 0.609956 45.6599 0.599956 45.6999C0.499956 46.0799 0.509956 46.2999 0.509956 46.2999C0.419956 49.1699 4.63996 52.1899 12.1 54.5899C19.89 57.0899 30.32 58.6699 41.46 59.0299C63.84 59.7499 82.93 55.1299 83.13 48.9599C83.2 46.3799 80.37 44.7099 78.86 43.8099Z"
            />
            <path
              className={`${
                !white ? "fill-blue-2" : "fill-white"
              } origin-top-left transform-box-fill animate-scale-in`}
              d="M32.3299 44.29C40.0399 44.52 46.3299 43.3499 46.3899 41.6899C46.4199 40.9499 45.4099 40.5 44.8599 40.25C44.0099 39.87 43.1099 39.61 42.1999 39.4C41.0299 39.12 39.8199 38.8399 38.6199 38.7999C38.5899 38.8199 38.5999 38.8699 38.6299 38.8899C38.6499 38.9099 38.6899 38.93 38.7199 38.93C39.6199 39.19 40.5499 39.34 41.4199 39.72C41.7599 39.87 42.4299 40.1599 42.4099 40.6199C42.3699 41.7699 37.3299 42.5599 31.1399 42.3699C24.9499 42.1799 19.9799 41.1 20.0199 39.95C20.0199 39.86 20.0599 39.7799 20.1099 39.7099C20.1399 39.6699 20.1699 39.64 20.2099 39.61C20.8099 39.11 22.0699 38.7 22.0699 38.7C18.4699 39.59 18.4999 40.77 18.4999 40.77H18.5099C18.5099 40.8 18.4999 40.8199 18.4899 40.8499C18.4299 42.5299 24.6199 44.06 32.3299 44.29Z"
            />
            <path
              className={`${
                !white ? "fill-blue-2" : "fill-white"
              } origin-top-left transform-box-fill animate-scale-in`}
              d="M14.94 38.77C12.73 39.33 11.41 40.01 10.61 40.66C10.6 40.66 10.6 40.67 10.59 40.67C10.52 40.73 10.45 40.78 10.39 40.84C10.31 40.91 10.22 40.97 10.15 41.04C9.65996 41.51 9.40996 41.99 9.38996 42.46C9.33996 44.06 12.04 45.85 16.61 47.24C21.52 48.74 28.08 49.69 35.09 49.92C50.58 50.44 61.1 47.36 61.2 44.16C61.24 42.73 59.51 41.76 58.58 41.24C56.9 40.3 55.07 39.7 53.66 39.28C53.03 39.09 52.4 38.91 51.78 38.74C52.04 38.86 52.29 38.98 52.54 39.12C53.27 39.52 54.71 40.31 54.67 41.71C54.62 43.34 52.36 44.6 47.95 45.48C43.88 46.29 38.5 46.62 32.81 46.44C23.79 46.14 14.33 44.43 11.89 41.71C10.91 40.22 14.94 38.77 14.94 38.77Z"
            />
          </svg>
        )}
      </Link>
    ) : (
      <LogoSvg svgClasses={svgClasses} intro={intro} white={white} />
    )}
  </>
);

export default Logo;
