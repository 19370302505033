import React from "react";
import Link from "./link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";

// Handle Advanced Custom Field's (WordPress Plugin) link field type.
const CmsLink = ({ link, arrow, arrowBack, children, ...other }) => {
  if (link.target && !other["target"]) other["target"] = link.target;
  return (
    <Link to={link.url} {...other}>
      {children ? (
        children
      ) : (
        <>
          {arrowBack && <FontAwesomeIcon icon={faLongArrowAltLeft} />}{" "}
          {link.title + " "}{" "}
          {arrow && <FontAwesomeIcon icon={faLongArrowAltRight} />}
        </>
      )}
    </Link>
  );
};

export default CmsLink;
