import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";

const sharedAnimations = () => {
  // Registering a plugin multiple times doesn't hurt anything
  gsap.registerPlugin(ScrollTrigger, CustomEase);

  if (!sharedAnimations.defaultDuration) {
    sharedAnimations.defaultDuration = 0.4;
  }

  if (!sharedAnimations.generateTimeline) {
    sharedAnimations.generateTimeline = (section, config) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: section.current,
          start: config?.start ? config.start : "top bottom-=100",
          markers: config?.markers ? config.markers : false,
        },
        onComplete: () => {
          tl.kill();
        },
      });

      return tl;
    };
  }

  if (!CustomEase.get("default")) {
    CustomEase.create("default", "0.25, 0.64, 0.72, 0.92");
  }

  if (!gsap.effects.contentSlideFadeIn) {
    gsap.registerEffect({
      name: "contentSlideFadeIn",
      effect: (targets, config) => {
        return gsap.fromTo(
          targets,
          {
            opacity: 0,
            translateY: 100,
          },
          {
            opacity: 1,
            translateY: 0,
            duration: sharedAnimations.defaultDuration,
            ease: "default",
            stagger: sharedAnimations.defaultDuration,
          }
        );
      },
      extendTimeline: true,
    });
  }
};

export default sharedAnimations;
