import React from "react";
import Link from "../utilities/link";
import MobileGrandchildParent from "./mobile-grandchild-parent";

export default class MobileNavItem extends React.Component {
  constructor(props) {
    super(props);

    this.noHeight = "0";

    this.state = {
      contentHeight: this.noHeight,
      isOpen: false,
    };
    this.mobileNavContent = React.createRef();

    this.header = this.props.header;
  }

  toggleAccordion() {
    this.props.mobileNavItems.current.forEach((item) => {
      if (!item.state.isOpen) return;

      item.closeAccordion();
    });

    this.setState({
      contentHeight: this.getHeight(),
      isOpen: !this.state.isOpen,
    });
  }

  closeAccordion() {
    this.setState({
      isOpen: false,
    });
  }

  toggleNavClass = () => {
    this.header.current.classList.remove("menu-active");
  };

  getHeight() {
    return this.mobileNavContent.current.scrollHeight;
  }

  handleResize() {
    this.setState({
      isOpen: false,
    });
  }

  toggleNavMobile(e) {
    if (window.innerWidth > 1024) return;
    e.preventDefault();

    this.toggleAccordion();
  }

  handleChildLinkClick() {
    this.toggleNavClass();

    this.closeAccordion();
  }

  render() {
    {
      return (
        this.props.link.children.length !== 0 && (
          <li className="flex-1 group">
            <Link
              className={`typo-mega-menu-link block py-[19.5px]
                    transition text-white opacity-50 hover:opacity-100 relative after:bg-mega-menu-link-circle
                    after:block after:w-0 after:h-[2px] after:bottom-0 after:absolute
                    after:transition-all group-hover:after:w-full ${
                      this.state.isOpen ? "text-white" : ""
                    }`}
              to={this.props.link.path}
              onClick={(e) => this.toggleNavMobile(e)}
            >
              <span
                className="
                    inline-block
                  "
              >
                {this.props.link.label}
              </span>
            </Link>
            <div
              className={`nav-primary-child-links grid transition-all ${
                this.state.isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
              }`}
            >
              <ul
                ref={this.mobileNavContent}
                className={`overflow-hidden min-h-0 transition-all typo-nav-link text-gray-light-2 font-[700] tracking-[-1.105px] text-[13px]`}
              >
                <li className="pt-[30px] pb-[30px]">
                  <Link
                    className="nav-primary-child-link flex transition-all hover:text-green-3 justify-between"
                    to={this.props.link.path}
                    onClick={() => this.handleChildLinkClick()}
                  >
                    {this.props.link.icon.mobileText}
                  </Link>
                </li>
                <MobileGrandchildParent
                  links={this.props.link.children}
                  closeParents={this.handleChildLinkClick.bind(this)}
                />
              </ul>
            </div>
          </li>
        )
      );
    }
  }
}
