import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faStar, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { Script } from "gatsby";

library.add(fab, faStar, faLongArrowAltRight);

export const ShowIntroContext = React.createContext(false);

export default function Layout({ location, children }) {
  let [introCompleted, setIntroCompleted] = useState(true);

  useEffect(() => {
    if (!localStorage) return;

    setIntroCompleted(localStorage.getItem("introCompleted"));
  });

  return (
    <div id="layout" className="min-h-screen bg-blue-gradient font-body">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
      </Helmet>
      {/*<Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#8dc63f" />
        <meta name="msapplication-TileColor" content="#8dc63f" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>*/}
      <ShowIntroContext.Provider
        value={location.pathname === "/" && !introCompleted}
      >
        <Header />
        <main id="main">{children}</main>
        <Footer />
        <Script id="accessibe-script">{`(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, triggerRadius : '20' } }); }; h.appendChild(s); })();`}</Script>
      </ShowIntroContext.Provider>
    </div>
  );
}
