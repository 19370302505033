import React, { useLayoutEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Link from "../utilities/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../utilities/logo";
import CmsImage from "../utilities/cms-image";
import CmsLink from "../utilities/cms-link";
import Content from "../utilities/content";
import NewsletterSignup from "../utilities/newsletter-signup";
import { gsap } from "gsap";
import sharedAnimations from "../../utilities/shared-animations";

const Footer = () => {
  const {
    wp: {
      themeSettings: { themeSettings: footer },
    },
    socialNav: {
      menuItems: { nodes: socialNavLinks },
    },
    footerNav: {
      menuItems: { nodes: footerNavLinks },
    },
  } = useStaticQuery(
    graphql`
      query Footer {
        wp {
          themeSettings {
            themeSettings {
              copyright
              footerContactUs
              footerFollowUs
              footerNewsletter
              textUnderFooterLogo
              payOnlineButton {
                target
                title
                url
              }
              googlePartnerBadge {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 175, height: 167)
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
        socialNav: wpMenu(slug: { eq: "social" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
            }
          }
        }
        footerNav: wpMenu(slug: { eq: "footer" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
            }
          }
        }
      }
    `
  );

  const cookieLink = function (e) {
    e.preventDefault();
    window.displayPreferenceModal();
    return false;
  };

  const year = new Date().getFullYear(),
    copyright = footer.copyright.replace(/{year}/, year.toString()),
    footerLinkClasses = "typo-footer-link py-[10px] sm:py-[20px] px-[10px]";

  const section = useRef(),
    sectionSelector = gsap.utils.selector(section);

  sharedAnimations();

  useLayoutEffect(() => {
    let tl = sharedAnimations.generateTimeline(section);

    if (window.innerWidth >= 1024) {
      tl.contentSlideFadeIn(sectionSelector("#footer-logos > *"), {}, 0)
        .contentSlideFadeIn(sectionSelector("#footer-pay > *"), {}, 0)
        .contentSlideFadeIn(
          sectionSelector("#footer-contact-social > *"),
          {},
          0
        )
        .contentSlideFadeIn(sectionSelector("#footer-contact-form > *"), {}, 0);
    } else {
      tl.contentSlideFadeIn(
        sectionSelector("#footer-logos, #footer-extra > *"),
        {},
        0
      );
    }

    return () => {
      tl.kill();
    };
  }, [sectionSelector]);

  return (
    <footer id="footer" ref={section} className="relative">
      <div
        id="footer-main"
        className="bg-blue-2 flex lg:justify-between flex-col lg:flex-row items-center lg:items-stretch px-x md:px-[59px] py-y md:pt-[70px] md:pb-[61px]"
      >
        <div
          id="footer-logos"
          className="flex flex-col items-center mb-y lg:mb-0"
        >
          <Logo
            className="mb-[13px]"
            white
            svgClasses="max-w-[210px]"
            link={true}
          />
          <Content className="typo-logo-sub-title max-w-[179px] text-center mb-[25px]">
            {footer.textUnderFooterLogo}
          </Content>
          <CmsImage image={footer.googlePartnerBadge} />
          <div className="ccpa text-white hover:text-green-1 text-center mt-[20px] max-w-[175px]">
            <a
              href="https://app.termly.io/notify/3d78be3b-53e4-45da-bda2-f40db3803527"
              target="_blank"
            >
              Do Not Sell or Share My Personal information
            </a>
          </div>
        </div>
        <div
          id="footer-extra"
          className="grid grid-cols-1 md:grid-cols-[auto,194px,235px] gap-x-[43px]"
        >
          <div
            id="footer-pay"
            className="pr-0 md:pr-[3px] flex flex-row md:flex-col mb-y-sm md:mb-0"
          >
            <CmsLink
              className="button button-secondary button-small"
              link={footer.payOnlineButton}
            />
          </div>
          <div id="footer-contact-social" className="mb-y-sm md:mb-0">
            <Content className="content-footer-extra mb-[15px]">
              {footer.footerContactUs}
            </Content>
            <hr className="border-gray-blue-hr mb-[20px]" />
            <div id="footer-social">
              <Content className="content-footer-extra">
                {footer.footerFollowUs}
              </Content>
              <ul className="flex space-x-[14px]">
                {socialNavLinks.map((link) => (
                  <li key={link.id}>
                    <Link
                      to={link.path}
                      className="typo-footer-extra text-[18px] text-white"
                    >
                      <FontAwesomeIcon icon={["fab", link.label]} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div id="footer-contact-form">
            <Content className="content-footer-extra mb-[12px]">
              {footer.footerNewsletter}
            </Content>
            <NewsletterSignup />
          </div>
        </div>
      </div>
      <div
        id="footer-info"
        className="bg-blue-3 px-x md:px-[30px] pt-[10px] pb-[20px] sm:pt-0 lg:pb-0"
      >
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <nav id="nav-footer" className="mb-[10px] sm:mb-0">
            <ul className="flex items-center justify-center flex-col sm:flex-row">
              <li>
                <a
                  href="#"
                  onClick={(e) => cookieLink(e)}
                  id="termly-consent-preferences"
                  className="typo-footer-link py-[10px] sm:py-[20px] px-[10px] block hover:text-green-3"
                >
                  Consent Preferences
                </a>
              </li>
              {footerNavLinks.map((link) => (
                <li key={link.id}>
                  {link.path !== "#" ? (
                    <Link
                      to={link.path}
                      className={`${footerLinkClasses} block hover:text-green-3`}
                    >
                      {link.label}
                    </Link>
                  ) : (
                    <div
                      className={`${footerLinkClasses} text-center hover:text-white`}
                    >
                      <FontAwesomeIcon icon="star" className="text-green-2" />{" "}
                      {link.label}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <Content
            id="copyright"
            className="typo-copyright flex flex-col justify-center text-center lg:text-left"
          >
            {copyright}
          </Content>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
