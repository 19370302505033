import React, { useContext, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import formatWpMenu from "../../utilities/format-wp-menu";
import Link from "../utilities/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import CmsImage from "../utilities/cms-image";
import ContactIcons from "../../images/icons-contact.inline.svg";
import Logo from "../utilities/logo";
import MobileNavDropdown from "../utilities/mobile-nav-dropdown";
import { ShowIntroContext } from "./layout";

const Header = () => {
  const {
    headerNav: {
      menuItems: { nodes: headerNavLinks },
    },
    headerSecondaryNav: {
      menuItems: { nodes: headerSecondaryNavLinks },
    },
    primaryNav: {
      menuItems: { nodes: primaryNavLinks },
    },
    secondaryNav: {
      menuItems: { nodes: secondaryNavLinks },
    },
  } = useStaticQuery(
    graphql`
      query Header {
        headerNav: wpMenu(slug: { eq: "header" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
            }
          }
        }
        headerSecondaryNav: wpMenu(slug: { eq: "header-secondary" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
            }
          }
        }
        primaryNav: wpMenu(slug: { eq: "primary" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
              icon: primaryMenu {
                mobileText
                subTitle
                description
                buttonText
                icon {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                    extension
                    publicURL
                  }
                }
                sublinks {
                  sublinkText
                  sublinkUrl
                }
              }
            }
          }
        }
        secondaryNav: wpMenu(slug: { eq: "secondary" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
            }
          }
        }
      }
    `
  );

  const primaryNav = formatWpMenu(primaryNavLinks),
    header = useRef(null);

  const toggleNavClass = () => {
    header.current.classList.toggle("menu-active");
  };

  const toggleNav = (e) => {
    e.preventDefault();
    toggleNavClass();
  };

  const showIntro = useContext(ShowIntroContext);

  return (
    <header
      id="header"
      className={`${showIntro ? "fixed" : "sticky"} top-0 inset-x-0 z-30`}
      ref={header}
    >
      <div
        id="header-main"
        className="bg-white flex justify-between pl-x md:pl-[37px] h-header-height"
      >
        <div id="logo-holder" className="flex">
          <div
            id="logo"
            className="flex sm:block items-center sm:pt-[18.5px] h-full"
          >
            <Logo icon link={true} />
          </div>
          {headerSecondaryNavLinks && (
            <ul className="ml-x-sm sm:ml-x">
              {headerSecondaryNavLinks.map((link) => (
                <li key={link.id} className="h-full">
                  <Link
                    className={`typo-nav-link hover:text-green-3 flex items-center h-full font-bold`}
                    to={link.path}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
        <nav id="nav" className="flex">
          <ul
            id="nav-header"
            className="hidden sm:flex space-x-x xs:space-x-[26.5px] sm:space-x-x md:space-x-[36px] mr-0 lg:mr-x-sm"
          >
            {headerNavLinks.map((link, i) => (
              <li key={link.id} className="flex items-center">
                <Link className="button button-small" to={link.path}>
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
          <a
            id="menu-link-open"
            className="flex px-x xs:px-[26.5px] items-center group transition-none"
            href="#menu"
            onClick={toggleNav}
          >
            <FontAwesomeIcon
              className="text-green-3 mr-[13.5px]"
              icon={faPlus}
            />
            <div className="typo-nav-link font-bold group-hover:text-green-3 transition">
              Menu
            </div>
          </a>
        </nav>
      </div>
      <div
        id="mega-menu"
        className="absolute top-0 inset-x-0 flex flex-col max-h-screen overflow-y-auto md:overflow-y-visible"
      >
        <nav
          id="mega-menu-secondary"
          className="bg-white lg:bg-blue-2 flex justify-between order-2 lg:order-1"
        >
          <ul
            id="nav-secondary"
            className="flex flex-col lg:flex-row py-[15px] md:py-0"
          >
            {secondaryNavLinks.map((link) => (
              <li key={link.id}>
                <Link
                  className="typo-nav-link block text-gray-2 lg:text-white hover:text-green-3 py-[15px] md:pt-[22px] md:pb-[18px] px-[27px]"
                  to={link.path}
                  onClick={toggleNavClass}
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
          <a
            id="menu-link-close"
            className="flex px-x xs:px-[26.5px] items-center group transition-none absolute right-0 top-0 lg:static py-[20px] lg:py-0 z-10"
            href="#menu"
            onClick={toggleNav}
          >
            <FontAwesomeIcon
              className="text-green-3 mr-[13.5px]"
              icon={faTimes}
            />
            <div className="typo-nav-link font-bold transition text-white group-hover:text-green-3">
              Menu
            </div>
          </a>
        </nav>
        <nav
          id="mega-menu-primary"
          className="bg-blue-2 lg:bg-white px-mega-menu-x relative order-1 lg:order-2"
        >
          <ul
            id="nav-primary"
            className="hidden lg:flex flex-col lg:flex-row py-[19.5px] md:py-0"
          >
            {primaryNav.map((link) => (
              <li key={link.id} className="flex-1 group">
                <Link
                  className="
                    typo-mega-menu-link block py-[19.5px] md:py-[39px]
                    transition group-hover:text-blue-2
                  "
                  to={link.path}
                  onClick={toggleNavClass}
                >
                  <span
                    className="
                    inline-block relative after:bg-mega-menu-link-circle
                    after:block after:w-0 after:h-[2px] after:bottom-[-8px] after:absolute
                    after:transition-all group-hover:after:w-full
                  "
                  >
                    {link.label}
                  </span>
                </Link>
                <ul
                  className={`
                    absolute inset-x-0 top-full grid bg-white px-mega-menu-x hidden lg:grid
                    border-gray-light-3 border border-t-0
                    ${
                      link.children.length > 4
                        ? "after:content-[url(../images/mega-menu-leaves.jpg)] after:absolute " +
                          "after:-right-[1px] after:-bottom-[1px] after:block after:leading-[0]"
                        : ""
                    }
                    ${
                      link.children.length === 0
                        ? "grid-cols-4"
                        : "grid-cols-5 gap-x-[20px]"
                    }
                    transition-all opacity-0 invisible pointer-events-none transform-gpu -translate-y-[4rem]
                    group-hover:opacity-100 group-hover:visible group-hover:pointer-events-auto group-hover:-translate-y-[1px]
                  `}
                >
                  {link.children.length === 0 && (
                    <li className="absolute left-0 bottom-0 pointer-events-none">
                      <ContactIcons />
                    </li>
                  )}
                  <li
                    className={`row-span-2 relative z-10 ${
                      link.children.length === 0 ? "col-start-4" : ""
                    }`}
                  >
                    <Link
                      className="block"
                      to={link.path}
                      onClick={toggleNavClass}
                    >
                      {link.icon.description ? (
                        <>
                          <div className="typo-content-strong mb-[17px]">
                            {link.icon.subTitle}
                          </div>
                          <div className="typo-paragraph-text mb-[15px]">
                            {link.icon.description}
                          </div>
                        </>
                      ) : link.icon.sublinks ? (
                        <>
                          <div className="typo-mega-menu-sub-title mb-[17px]">
                            {link.icon.subTitle}
                          </div>
                          <div className="typo-paragraph-text mb-[15px]">
                            <ul>
                              {link.icon.sublinks.map((link, index) => {
                                <li key={index}>
                                  <Link to={link.sublinkUrl}>
                                    {link.sublinkText}
                                  </Link>
                                </li>;
                              })}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <div className="typo-mega-menu-sub-title mb-[17px]">
                          {link.icon.subTitle}
                        </div>
                      )}
                      <button className="button mb-[62px]">
                        {link.icon.buttonText}
                      </button>
                    </Link>
                  </li>
                  {link.children.map((childLink, index) => (
                    <li className="relative z-10" key={index}>
                      <Link
                        className="nav-primary-child-link flex flex-col items-center pb-[24px] text-center"
                        to={childLink.path}
                        onClick={toggleNavClass}
                      >
                        <div className="nav-primary-child-top">
                          <div
                            className={`nav-primary-child-img flex justify-center items-center ${
                              link.children.length > 4
                                ? "h-[44px] mb-[11px]"
                                : "h-[95px] mb-[23px]"
                            }`}
                          >
                            <CmsImage image={childLink.icon.icon} />
                          </div>
                          <div className="typo-content-strong transition">
                            {childLink.icon.subTitle}
                          </div>
                        </div>
                        {childLink.icon.sublinks ? (
                          <div className="typo-supporting-text mt-[6px]">
                            <ul className="gap-y-[8px] flex flex-col mt-[16px]">
                              {childLink.icon.sublinks.map((link) => (
                                <li
                                  key={link.id}
                                  className="mx-[1px] uppercase font-[600] tracking-[0.96px] hover:text-[#8DC640]"
                                >
                                  <Link to={link.sublinkUrl}>
                                    {link.sublinkText}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          childLink.icon.description && (
                            <div className="typo-supporting-text mt-[6px]">
                              {childLink.icon.description}
                            </div>
                          )
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <ul
            id="nav-primary-mobile"
            className="flex lg:hidden flex-col py-[19.5px]"
          >
            <MobileNavDropdown items={primaryNav} header={header} />
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
