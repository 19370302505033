import React, { useEffect, useRef } from "react";
import MobileNavItem from "./mobile-nav-item";

const MobileNavDropdown = (props) => {
  let mobileNavItems = useRef([]);

  const handleResize = () => {
    mobileNavItems.current.forEach((item) => {
      if (item.state.isOpen) {
        item.handleResize();
      }
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      {props.items.map((link, i) => (
        <MobileNavItem
          ref={(elem) => (mobileNavItems.current[i] = elem)}
          key={link.id}
          link={link}
          mobileNavItems={mobileNavItems}
          header={props.header}
        />
      ))}
    </>
  );
};

export default MobileNavDropdown;
