import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

/*
  This component is for handling when you don't know if an image that comes from the CMS will be a SVG or not.
  If you know that your image is/is-not svg, need to use StaticImage, or just the regular img tag,
  this component should not be needed for those uses.
*/

const CmsImage = ({ image, ...other }) => {
  // We need to use the regular img tag for svg & gif files.
  if (
    !image.localFile ||
    image.localFile.extension === "svg" ||
    image.localFile.extension === "gif"
  ) {
    return (
      <img
        src={!image.localFile ? image.sourceUrl : image.localFile.publicURL}
        alt={image.altText}
        {...other}
      />
    );
  }

  // Use GatsbyImage for all other images that come from our CMS.
  const img = getImage(image.localFile);
  return <GatsbyImage alt={image.altText} image={img} {...other} />;
};

export default CmsImage;
