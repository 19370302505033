import React, { useState } from "react";
import Link from "./link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownLong,
  faArrowUpLong,
} from "@fortawesome/free-solid-svg-icons";

export default function MobileGrandchildParent({ links, closeParents }) {
  const [activeChild, setActiveChild] = useState(null);

  const handleLinkClick = (e, id, hasSubLinks) => {
    if (!hasSubLinks) {
      closeParents();

      return;
    }

    e.preventDefault();

    if (activeChild === id) {
      setActiveChild(null);

      return;
    }
    setActiveChild(id);
  };

  const handleChildLinkClick = () => {
    setActiveChild(null);

    closeParents();
  };

  return (
    <>
      {links.map((link) => (
        <li className="pb-[35px]" key={link.id}>
          <Link
            className="nav-primary-child-link flex gap-[5px] transition-all hover:text-green-3"
            to={link.path}
            onClick={(e) =>
              handleLinkClick(e, link.id, link.icon.sublinks?.length)
            }
          >
            {link.icon.subTitle}
            {link.icon.sublinks && (
              <FontAwesomeIcon
                icon={activeChild === link.id ? faArrowUpLong : faArrowDownLong}
              />
            )}
          </Link>
          {link.icon.sublinks && (
            <div
              className={`nav-primary-grandchild-links grid transition-all ${
                activeChild === link.id ? `grid-rows-[1fr]` : `grid-rows-[0fr]`
              }`}
            >
              <ul
                className={`overflow-hidden transition-all typo-nav-link min-h-0 flex flex-col gap-[30px]`}
              >
                {link.icon.sublinks.map((childlink) => (
                  <li
                    key={childlink.sublinkText}
                    className="nav-primary-grandchild-link first-of-type:pt-[30px]"
                  >
                    <Link
                      onClick={() => handleChildLinkClick()}
                      className="text-white hover:text-green-3 transition-all"
                      to={childlink.sublinkUrl}
                    >
                      {childlink.sublinkText}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
    </>
  );
}
